import axios from 'axios'
import {axiosTransformErrorResponseHandler} from '../../../../common/api/common.api';

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/me`
export const USERS_URL = `${API_URL}/users`
export const LOGIN_URL = `${API_URL}/auth/signin`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const GET_CODE_URL = `${API_URL}/auth/getcode`
export const VERIFY_CODE_URL = `${API_URL}/auth/verify`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const UPDATE_ME_URL = `${API_URL}/auth/me`
export const GET_EMPLOYEE_URL = `${API_URL}/employees`
export const KPI_ADMIN_URL = `${API_URL}/kpi/company`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios({
    method: 'post',
    url: LOGIN_URL,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { email, password }
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  const data = {
    "email": email,
    "firstName": firstname,
    "lastName": lastname,
    "password": password,
    "passwordConfirmation": password_confirmation,
    "role": "super_admin"
  };

  return axios({
    method: 'post',
    url: REGISTER_URL,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function resetPassword(password: string, token: string) {
  return axios({
    method: 'patch',
    url: UPDATE_ME_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: {
      "password": password,
      "passwordConfirmation": password
    }
  })
    .catch(err => axiosTransformErrorResponseHandler(err, 'Error resetting password'));

}

export function updateUser(firstname: string, lastname: string, password: string, password_confirmation: string, token: string) {
  return axios({
    method: 'patch',
    url: UPDATE_ME_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: {
      "firstName": firstname,
      "lastName": lastname,
      "password": password,
      "passwordConfirmation": password_confirmation
    }
  })
}

export function updateUsername(firstname: string, lastname: string, token: string) {
  return axios({
    method: 'patch',
    url: UPDATE_ME_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: {
      "firstName": firstname,
      "lastName": lastname,
    }
  })
}

export function updateUsernameAndPhone(firstname: string, lastname: string, phone: string, token: string) {
  return axios({
    method: 'patch',
    url: UPDATE_ME_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: {
      "firstName": firstname,
      "lastName": lastname,
      phone: phone
    }
  })
}

export function updateUserNotificationSubscriptions(subscriptions: string[], token: string) {
  return axios({
    method: 'patch',
    url: UPDATE_ME_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: {
      notificationSubscriptions: subscriptions
    }
  })
}

export function getUserByToken(token: string) {
  return axios({
    method: 'get',
    url: GET_USER_BY_ACCESSTOKEN_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function sendCode(email: string, token: string) {
  return axios({
    method: 'get',
    url: `${GET_CODE_URL}?email=${encodeURIComponent(email)}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function verifyCode(email: string, codeString: string, token: string) {
  return axios({
    method: 'post',
    url: VERIFY_CODE_URL,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: {
      "email": email,
      "code": codeString
    }
  })
}

export function setUserAvatar(
  userId: number,
  requestBody: FormData,
  token: string
) {
  return axios.post(`${USERS_URL}/${userId}/avatar`, requestBody,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    }
  )
}

// export const getEmployee = async (token: string) => {
//   const response = await fetch(`${GET_EMPLOYEE_URL}/`, {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json;charset=UTF-8",
//       'Authorization': `Bearer ${token}`
//     },
//   });
//   const data = await response.json();
//   for (let i = 0; i < data.length; i++) {
//     data[i].fullName = data[i].firstName + " " + data[i].lastName
//     let connection = (await getEmployeeConnection(data[i].id, token)).data
//     data[i].last_connection = connection.length > 0 ? moment(connection.pop().created_at).format('YYYY-MM-DD') : 'None'
//   }
//   if (response.status >= 400) {
//     throw new Error(data.errors || data.msg);
//   }
//   return data;
// };

export function getAdminDashboardForCount(companyId: string, token: string) {
  return axios({
    method: 'get',
    url: `${KPI_ADMIN_URL}/${companyId}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}
