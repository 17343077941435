import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { Logger } from '../../../log/components/Logger';
import { SkeDomain } from '../../../log/ske-exception';
import { coachingModalSlice, CoachingModalState, CoachingModalStateUnion } from '../../coaching-modal.slice';
import { coachingSlice } from '../../coaching.api';
import { CoachingModal } from '../modals/CoachingModal';

interface Props {
	state: CoachingModalStateUnion & Pick<CoachingModalState, 'showModal' | 'recordId'>;
}

export function CoachingLayout({state}: Props) {
	const [deleteCoaching] = coachingSlice.useDeleteCoachingMutation();
	const coachingViewModalRef = useRef<HTMLButtonElement>(null);
	const coachingEditModalRef = useRef<HTMLButtonElement>(null);
	const previousState = useRef<CoachingModalStateUnion>();
	const [getOneCoachingFilter, setGetOneCoachingFilter] = useState<{id: number}>();
	const {
		data: coaching,
		refetch,
		isUninitialized,
		fulfilledTimeStamp,
	} = coachingSlice.useGetOneCoachingQuery(getOneCoachingFilter || skipToken);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!state.record && state.recordId) {
			const force = !state.record && !!previousState.current?.record;
			handleLoadRecord(state.recordId, force);
			return;
		}
		if (state.record && state.record?.id === state.recordId && state.showModal) {
			switch (state.mode) {
				case ModalDisplayMode.Read:
					coachingViewModalRef.current?.click();
					break;
				case ModalDisplayMode.Update:
					coachingEditModalRef.current?.click();
					break;
			}
			previousState.current = state;
			return;
		}
	}, [state]);

	useEffect(() => {
		if (!!coaching && coaching.id === state.recordId) {
			dispatch(coachingModalSlice.actions.show({
				...state,
				record: coaching,
			}));
		}
	}, [coaching, fulfilledTimeStamp]);

	const handleLoadRecord = (id: number, force: boolean) => {
		setGetOneCoachingFilter({id});
		if (force) {
			if (isUninitialized) {
				return;
			}
			refetch();
		}
	};

	const handleCancelDelete = () => {
		dispatch(coachingModalSlice.actions.hide());
	};

	const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
		if (state.mode !== ModalDisplayMode.Delete || !state.recordId) {
			throw new Error('Unable to delete record as no ID was passed in');
		}
		deleteCoaching({id: state.recordId})
			.unwrap()
			.then(() => {
				successToast();
				dispatch(coachingModalSlice.actions.hide());
			})
			.catch(() => {
				errorToast();
				dispatch(coachingModalSlice.actions.hide());
			});
	};

	useEffect(() => {
		Logger('CoachingState change detected', SkeDomain.Coaching, {misc: {state}});
	}, [state]);

	return (
		<>
			{state.record && state.mode === ModalDisplayMode.Read &&
				<CoachingModal
					show={state.showModal}
					coaching={state.record}
					employee={state.employee}
					title={'Coaching'}
					type={'view'}
				/>
			}
			{state.record && state.mode === ModalDisplayMode.Update &&
				<CoachingModal
					show={state.showModal}
					coaching={state.record}
					employee={state.employee}
					title={'Edit Coaching'}
					type={'edit'}
				/>
			}
			{state.mode === ModalDisplayMode.Create &&
				<CoachingModal
					show={state.showModal}
					employee={state.employee}
					title={'New Coaching Connection'}
					type={'new'}
				/>
			}
			{state.mode === ModalDisplayMode.Delete &&
				<SkeDialogDelete
					onCancel={handleCancelDelete}
					onConfirm={handleConfirmDelete}
					successMessage="Coaching deleted"
					message="Are you sure you want to delete this?"
				/>
			}
		</>
	);
}
