import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom';
import { useFormik } from 'formik'
import { resetPassword } from '../redux/AuthCRUD'

const initialValues = {
    password: '',
}

const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Must include capital letter,' +
          ' special character and number'),
})

export function ResetPassword() {
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")

    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isShowPwd, setIsShowPwd] = useState(false)
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                if (token) {
                    resetPassword(values.password, token)
                        .then(({ data: { result } }) => {
                            setHasErrors(false)
                            setLoading(false)
                            navigate(`login`);
                        })
                        .catch((error) => {
                            console.log(error)
                            setErrorMessage(error)
                            setHasErrors(true)
                            setLoading(false)
                            setSubmitting(false)
                            // setStatus('The login detail is incorrect')
                        })
                } else {
                    setHasErrors(true)
                    setLoading(false)
                    setSubmitting(false)
                }
            }, 1000)
        },
    })

    return (
        <div className='d-flex flex-center flex-column flex-column-fluid p-20'>
            <div className='bg-white w-100 mw-500px'>
                <form
                    className='form fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_password_reset_form'
                    onSubmit={formik.handleSubmit}
                >
                    {/* begin::Title */}
                    <h1 className='form_title text-center mb-10'>Reset Password</h1>
                    {/* end::Title */}

                    {/* begin::Title */}
                    {hasErrors === true && (
                        <div className='mb-lg-15 alert alert-danger'>
                          <div className='alert-text font-weight-bold'>
                              {errorMessage}
                          </div>
                        </div>
                    )}

                    {hasErrors === false && (
                        <div className='my-10 alert alert-success p-8 rounded'>
                            Your password has been successfully changed.
                        </div>
                    )}
                    {/* end::Title */}

                    {/* begin::Form group */}
                    <label htmlFor="new-password">New Password</label>
                    <div className="fv-row form_control_wrapper position-relative">
                        <i className="bi bi-lock fs-2 position-absolute"></i>
                        <button
                          type="button"
                          className="position-absolute btn p-0 btn_show_password"
                          onClick={() => setIsShowPwd(!isShowPwd)}
                        >
                            {
                                isShowPwd ?
                                  <i className="bi bi-eye-slash-fill fs-2"></i> :
                                  <i className="bi bi-eye-fill fs-2"></i>
                            }
                        </button>
                        <input
                          id="new-password"
                          type={isShowPwd ? 'text' : 'password'}
                          placeholder="Password"
                          autoComplete="off"
                          {...formik.getFieldProps('password')}
                          className={clsx(
                            'form_control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.password && formik.errors.password},
                            {
                                'is-valid': formik.touched.password && !formik.errors.password,
                            },
                          )}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                  <span role="alert">{formik.errors.password}</span>
                              </div>
                          </div>
                        )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className="d-flex justify-content-end flex-wrap margin-top-20 gap-5">
                        <button
                          type="submit"
                          id="kt_password_reset_submit"
                          className='btn btn-lg btn_submit fw-bolder btn-primary'
                        >
                            {!loading && <span className='indicator-label poppins_medium'>Reset</span>}
                            {loading && (
                                <span className='indicator-progress poppins_medium' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                        <Link to='/auth/login'>
                            <button
                                type='button'
                                id='kt_login_password_reset_form_cancel_button'
                                className='btn btn-lg btn-light fw-bolder btn-secondary'
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                Cancel
                            </button>
                        </Link>{' '}
                    </div>
                    {/* end::Form group */}
                </form>
            </div>
        </div>
    )
}
