import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import { ForgotPassword } from './components/ForgotPassword'
import { ResetPassword } from './components/ResetPassword'
import { Login } from './components/Login'
import { LoginWithInvite } from './components/LoginWithInvite'
import './AuthPage.scss'
import leftBackground from '../../../images/auth/login_background.png'
import simpleLogo from '../../../images/logos/pure_simple_logo.png'

export function AuthPage() {
	useEffect(() => {
		document.body.classList.add('bg-white')
		return () => {
			document.body.classList.remove('bg-white')
		}
	}, [])

	return (
		<div className='auth_container container-fluid min-vh-100 d-flex flex-column flex-lg-row p-0'>
			<div className='p-4 col-lg-6 d-flex flex-column align-items-center justify-content-center position-relative bg-light flex-grow-1 flex-lg-fill'>
				<img
					src={leftBackground}
					alt='Background'
					className='d-lg-block position-absolute w-100 h-100 object-fit-cover'
				/>
				<img
					src={simpleLogo}
					alt='Logo'
					className='img-fluid position-relative responsive-logo'
				/>
			</div>

			<div className='col-lg-6 d-flex flex-column flex-grow-1 flex-lg-fill p-0 overflow-auto'>
        <div className="my-auto">
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/login-with-invite' element={<LoginWithInvite />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/resetpassword' element={<ResetPassword />} />
            <Route path='*' element={<Navigate to='/login' />} />
          </Routes>
        </div>
			</div>
		</div>
	)
}
