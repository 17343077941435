import { useRef } from 'react';
import { Recognition } from '../../../../../features/recognition/interfaces/recognition.model';
import { EmployeeType } from '../../../../types/EmployeeType'
import { EmployeeCardContent } from './EmployeeCardContent'
import ReactToPrint from 'react-to-print'
import './EmployeePerformanceCard.scss'
import { AttendanceType } from '../../../../types/AttendanceType'
import { CoachingType } from '../../../../types/CoachingType'
import { DisciplineType } from '../../../../types/DisciplineType'
import { PerformanceReportConfigType } from '../../../../types/PerformanceReportConfigType';
import {SkeModal} from '../../../../../common/modals/generic/SkeModal';

interface Props {
	employee: EmployeeType;
	attendances: AttendanceType[];
	coachings: CoachingType[];
	recognitions: Recognition[];
	disciplines: DisciplineType[];
	config: PerformanceReportConfigType;
	show: boolean;
	onClose: () => void;
}

export const EmployeePerformanceCard = ({
	employee,
	attendances,
	coachings,
	recognitions,
	disciplines,
	config,
	show,
	onClose
}: Props) => {
	const pdfCardRef = useRef<HTMLDivElement>(null)

	return (
		<SkeModal
			show={show}
			title=''
			showFooter={false}
			dialogClassName='modal-fit-content'
			onClose={onClose}
			modalBodyCustomClass="p-0"
			headerContents={
				<ReactToPrint
					content={() => pdfCardRef.current}
					trigger={() => <button className='btn btn-primary'>Print to PDF!</button>}
				/>
			}
		>
			<EmployeeCardContent
				ref={pdfCardRef}
				employee={employee}
				attendances={attendances}
				coachings={coachings}
				recognitions={recognitions}
				disciplines={disciplines}
				config={config}
			/>
		</SkeModal>
	)
}
