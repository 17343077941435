import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux'
import { PageTitle } from '../../../../_library/layout/core'
import { toAbsoluteUrl } from '../../../../_library/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { teamSlice } from '../../../../features/team/team.api';
import { TeamUpdateApiRequestModel } from '../../../../features/team/interfaces/team.model';
import { getSupervisors, } from '../../../CRUD/TeamCRUD'
import { RootState } from '../../../../setup';
import { UserModel } from '../../../models';
import _ from 'lodash';
import { SkeTooltip } from "../../../modules/shared/components/Tooltip";

const multiSelectCustomStyles = {
    option: (provided: any) => ({
        ...provided,
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: '#eef3f7',
        borderColor: '#eef3f7'
    }),
    multiValue: (provided: any) => ({
        ...provided,
        color: '#5E6278'
    })
}

interface IProfileDetails {
    name: string
    location: string
    department: string
    avatar: any
    shift: string
}

const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().required('Team name is required'),
    location: Yup.string().required('Location is required'),
    department: Yup.string().required('Department ID is required'),
    shift: Yup.string().required('Shift is required')
})

const initialValues: IProfileDetails = {
    name: '',
    location: '',
    department: '',
    avatar: '',
    shift: '',
}

type RouteParams = {
    id: string;
}

interface Option {
    value: string
    label: string
}

export function EditTeam() {
    const { id } = useParams<RouteParams>();
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const navigate = useNavigate();
    const [initAvatar, setInitAvatar] = useState('')
    const [editedTeam, setEditedTeam] = useState<IProfileDetails>(initialValues)
    const [isLoading, setIsLoading] = useState(false)
    const [avatarFile, setAvatarFile] = useState<File>()
    const [supervisors, setSupervisors] = useState<UserModel[]>([])
    const [newSupervisorList, setNewSupervisorList] = useState<Option[]>([])
    const [oldSupervisorList, setOldSupervisorList] = useState<Option[]>([])
    const { data: savedTeam } = teamSlice.useGetOneTeamQuery(id ? {team_id: +id, includeSupervisors: true} : skipToken);
    const { data: teamSupervisors } = teamSlice.useGetSupervisorsQuery(id ? {team_id: +id} : skipToken);
    const [updateTeam, {isSuccess: teamUpdateSuccess, isLoading: teamUpdateLoading}] = teamSlice.useUpdateTeamMutation();
    const [assignSupervisor, {isSuccess: assignSupSuccess, isLoading: assignSupLoading}] = teamSlice.useAssignSupervisorMutation();
    const [unassignSupervisor, {isSuccess: unassignSupSuccess, isLoading: unassignSupLoading}] = teamSlice.useUnassignSupervisorMutation();
    const [uploadAvatar, {isSuccess: avatarSuccess, isLoading: avatarLoading}] = teamSlice.useUploadAvatarMutation();

    const handleSelectChange = (option: readonly Option[]) => {
        setNewSupervisorList(option.map(opt => opt))
    }

    useEffect(() => {
        console.log(`edit team loaded ${id}`)
    }, [id]);

    useEffect(() => {
        if (savedTeam) {
            setEditedTeam({
                avatar: savedTeam.avatarUrl,
                department: savedTeam.department,
                name: savedTeam.name,
                location: savedTeam.location,
                shift: savedTeam.shift,
            });
        }
    }, [savedTeam]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            let file = e.target.files[0];
            setAvatarFile(file)
            reader.onloadend = () => {
                if (reader && reader.result) {
                    setEditedTeam({ ...editedTeam, avatar: reader.result })
                }
            }
            reader.readAsDataURL(file)
        }
    }

    const removeAvatar = () => {
        setAvatarFile(undefined)
        setEditedTeam({ ...editedTeam, avatar: '' })
    }

    useEffect(() => {
        if (supervisors?.length && teamSupervisors?.length) {
            const options: Option[] = supervisors.map(supervisor => ({
                  value: supervisor.email,
                  label: `${supervisor.firstName} ${supervisor.lastName}`,
              }),
            );
            const existingSupervisors: Option[] = teamSupervisors.map(supervisor => ({
                  value: supervisor.email,
                  label: `${supervisor.firstName} ${supervisor.lastName}`,
              }),
            );
            setNewSupervisorList(existingSupervisors);
            setOldSupervisorList(existingSupervisors);
        }
    }, [supervisors, teamSupervisors]);

    React.useEffect(() => {
        if (user.company) {
            getSupervisors(user.company.id, token)
                .then(({ data }) => {
                    setSupervisors(data.items)
                })
                .catch(err => {
                    console.log(err.response)
                })
        }
    }, [id, token, user.company])

    const formik = useFormik<IProfileDetails>({
			enableReinitialize: true,
			initialValues: editedTeam,
			validationSchema: profileDetailsSchema,
			onSubmit: (values, {setStatus, setSubmitting}) => {
				if (!id) {
					return
				}
        
				setIsLoading(true)

				const updateData: TeamUpdateApiRequestModel = {
					id: +id,
					name: values.name,
					location: values.location,
					department: values.department,
					shift: values.shift,
				}

				updateTeam(updateData)
					.then(async (res) => {
						console.log('done updating team', res)
						const asyncCalls = []

						// un-/assign supervisors
						const oldEmails = oldSupervisorList.map((option) => option.value)
						const newEmails = newSupervisorList.map((option) => option.value)
						const addedSupervisors = _.difference(newEmails, oldEmails)
						const removedSupervisors = _.difference(oldEmails, newEmails)

						addedSupervisors
							.filter((itm) => !!itm)
							.map(async (sup) => {
								asyncCalls.push(
									assignSupervisor({
										team_id: +id,
										email: sup,
									})
								)
							})

						removedSupervisors
							.filter((itm) => !!itm)
							.map(async (sup) => {
								asyncCalls.push(
									unassignSupervisor({
										team_id: +id,
										email: sup,
									})
								)
							})

						// change avatar, if applicable
						if (avatarFile) {
							asyncCalls.push(
								uploadAvatar({
									team_id: +id,
									avatarFile,
								})
							)
						}

						if (!asyncCalls.length) {
							setIsLoading(false)
							navigate(`/teams/show/${id}`)
						}

						return Promise.all(asyncCalls)
					})
					.then((res) => {
						console.log('finished post-processing team creation', res)
						navigate(`/teams/show/${id}`)
					})
					.catch((err) => {
						console.error('Errored post-processing after team creation', err)
					})
          .finally(() => {
            setIsLoading(false);
          })
			},
		})

    return (
        <>
            <PageTitle breadcrumbs={[]}>Edit Team</PageTitle>
            <form
                className='form mx-md-20 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_create_team_form'
                onSubmit={formik.handleSubmit}
            >
                {formik.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-lg-row-fluid">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade active show" id="kt_user_view_overview_tab" role="tabpanel">
                                <div className="card card-flush mb-6 mb-xl-9">
                                    <div className="card-header mt-6">
                                        <div className="card-title flex-column">
                                            <h2 className="mb-1">General</h2>
                                        </div>
                                    </div>
                                    <div className="card-body px-9 pt-0">
                                        <div className='col-lg-12'>
                                        <label className='col-form-label fw-bold fs-5'>Team Icon</label>
                                        <i
                                          id="team-icon-info"
                                          className="ms-1 bi bi-info-circle-fill text-info cursor-pointer"
                                        />
                                        <SkeTooltip
                                          targetElementId="team-icon-info"
                                          message="Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted"
                                        />
                                    <div className='text-center'>
                                        <div className="d-flex align-items-center justify-content-center rounded bg-white bg-body shadow w-200px h-200px">
                                            <div
                                                className={`image-input image-input-outline w-120px h-120px ${!editedTeam.avatar ? 'image-input-empty' : ''}`}
                                                data-kt-image-input='true'
                                                style={{
                                                    backgroundImage: `url(${!initAvatar ? toAbsoluteUrl('/media/icons/duotune/general/create-team-icon.svg')
                                                        : initAvatar.includes('media/icons/duotune/general/create-team-icon.svg') ? toAbsoluteUrl('/media/icons/duotune/general/create-team-icon.svg')
                                                            : initAvatar})`
                                                }}
                                            >
                                                <div
                                                    className='image-input-wrapper no-shadow shadow-none'
                                                    style={{ backgroundImage: `url(${toAbsoluteUrl(editedTeam.avatar)})` }}
                                                ></div>
                                                <label className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                                                    <i className="bi bi-pencil-fill fs-7"></i>
                                                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleFileChange} />
                                                    <input type="hidden" name="avatar_remove" />
                                                </label>
                                                <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="" data-bs-original-title="Remove avatar" onClick={removeAvatar}>
                                                    <i className="bi bi-x fs-2"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-5 mt-5'>Team Name</label>

                                            <div className='col-lg-12'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                    placeholder=''
                                                    {...formik.getFieldProps('name')}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.name}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <label className='col-form-label fw-bold fs-5 mt-5'>Supervisor(s)</label>

                                            <div className=''>
                                                <Select
                                                    styles={multiSelectCustomStyles}
                                                    isMulti={true}
                                                    value={newSupervisorList}
                                                    onChange={handleSelectChange}
                                                    options={supervisors.map(supervisor => ({
                                                        value: supervisor.email,
                                                        label: `${supervisor.firstName} ${supervisor.lastName}`,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className='col-form-label fw-bold fs-5 required mt-5'>Shift</label>

                                            <div>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                    placeholder=''
                                                    {...formik.getFieldProps('shift')}
                                                />
                                                {formik.touched.shift && formik.errors.shift && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.shift}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-column d-flex flex-column flex-lg-row row">
                                    <div>
                                        <div className="card card-flush mb-2 mb-xl-9">
                                            <div className="card-header mt-0">
                                                <div className="card-title flex-column">
                                                    <h2 className="mb-1">Location</h2>
                                                </div>
                                            </div>
                                            <div className="card-body d-flex flex-column pt-0">
                                                <label className='col-form-label required fw-bold fs-5'>Location Name</label>
                                                <div>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder=''
                                                        {...formik.getFieldProps('location')}
                                                    />
                                                    {formik.touched.location && formik.errors.location && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.location}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div  >
                                        <div className="card card-flush">
                                            <div className="card-header mt-0">
                                                <div className="card-title flex-column">
                                                    <h2 className="mb-1">Department</h2>
                                                </div>
                                            </div>
                                            <div className="card-body d-flex flex-column pt-0">
                                                <label className='col-form-label required fw-bold fs-5'>Department Name</label>
                                                <div className='col-lg-12'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder=''
                                                        {...formik.getFieldProps('department')}
                                                    />  
                                                    {formik.touched.department && formik.errors.department && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.department}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="ms-auto text-lg-end text-end mb-10">
                                    <button disabled={formik.isSubmitting} type="button" className="btn btn-secondary btn-sm mt-5 px-10">
                                        Cancel
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary btn-sm mt-5 ms-5 px-10'
                                        disabled={formik.isSubmitting || !formik.isValid || isLoading}
                                    >
                                        {!isLoading && <span className='indicator-label'>Update</span>}
                                        {isLoading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Updating team...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
