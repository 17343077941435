import clsx from 'clsx';
import { RequiredDeep, SetOptional } from 'type-fest';
import { Employee } from '../modules/employee/models/Employee.model';
import { Team } from '../modules/team/models/Team.model';
import { EmployeeType, TeamType } from '../types/EmployeeType';
import { Avatar, AvatarSize } from './Avatar';

export enum PersonBadgeOpts {
	Badge = 'BADGE',
	Email = 'EMAIL',
	Phone = 'PHONE',
	Team = 'TEAM',
}
interface Props {
	employee?: RequiredDeep<Pick<(Employee | EmployeeType), 'firstName' | 'lastName' | 'avatarUrl' >> & SetOptional<Pick<(Employee | EmployeeType), 'teams' | 'employeeId' | 'phone'>, 'teams' | 'employeeId' | 'phone'>;
	team?: Required<Pick<(Team | TeamType), 'name'>>;
	size?: AvatarSize;
	opts?: PersonBadgeOpts[];
	className?: string;
}

export function EmployeeBadge({ employee, team, className, size = AvatarSize.lg, opts }: Props) {
	const teamName = team?.name || employee?.teams?.[0]?.name || 'unassigned';
	return (
		<div className={clsx("px-4 py-2 align-items-center d-flex bg-secondary rounded-3 gap-4", className, {
			invisible: !employee
		})}>
			<Avatar
				className="flex-shrink-0"
				size={size}
				alt={`Employee ${employee?.firstName} ${employee?.lastName} avatar`}
				url={employee?.avatarUrl}/>
			<div className="d-flex w-100 flex-column justify-content-start align-items-start fs-7" >
				<div className="text-start d-flex gap-1">
					<label htmlFor="name" className="fw-bolder">Name: </label>
					<p id="name" className="m-0">{employee?.firstName} {employee?.lastName}</p>
				</div>
				{opts?.includes(PersonBadgeOpts.Badge) && (
					<div className="text-start d-flex gap-1">
						<label htmlFor="employee-id" className="fw-bolder">ID #: </label>
						<p id="employee-id" className="m-0">{employee?.employeeId}</p>
					</div>
				)}
				{opts?.includes(PersonBadgeOpts.Phone) && employee?.phone && (
					<div className="text-start d-flex gap-1">
						<label htmlFor="phone" className="fw-bolder">Phone #: </label>
						<p id="phone" className="m-0">{employee?.phone}</p>
					</div>
				)}
				<div className="text-start d-flex gap-1">
					<label htmlFor="team" className="fw-bolder">Team: </label>
					<p id="team" className="m-0">{teamName}</p>
				</div>
			</div>
		</div>
	)
}
