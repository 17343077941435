import clsx from 'clsx';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { SkeButtonLoading } from '../../../../common/components/ButtonLoading';
import { LocalStoragePrefix, useLocalStorage } from '../../../../common/hooks/useLocalStorage.hook';
import { teamSlice, TeamTagTypes } from '../../../../features/team/team.api';
import * as auth from '../redux/AuthRedux'
import { login } from '../redux/AuthCRUD'
import { toast } from 'react-toastify';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
    .matches(/^(?!.*(@gmail.com|@hotmail.com|@outlook.com|@yahoo.com)).*$/, 'Not Business Email'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initializeLoginFormValues = (email: string | undefined, rememberMe: boolean | undefined) => {
  const initialValues = {
    email: '',
    password: '',
    rememberMe: false
  }

  if(email) {
    initialValues.email = email;
  }

  if(rememberMe) {
    initialValues.rememberMe = rememberMe;
  }

  return initialValues;
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [isShowPwd, setIsShowPwd] = useState(false)
  const dispatch = useDispatch()
  dispatch(teamSlice.util.invalidateTags([TeamTagTypes.Teams]));

  const [email, setEmailLocalStorage, removeEmail] = useLocalStorage<string>(LocalStoragePrefix.Auth, 'email');
  const [rememberMe, setRememberMeLocalStorage, removeRememberMe] = useLocalStorage<boolean>(LocalStoragePrefix.Auth, 'rememberMe');

  const initialFormValues = initializeLoginFormValues(email, rememberMe);

  const setLocalStorageRememberMeValues = (rememberMe: boolean, email: string) => {
    setEmailLocalStorage(email);
    setRememberMeLocalStorage(rememberMe);
  };

  const removeLocalStorageRememberMeValues = () => {
    removeEmail();
    removeRememberMe();
  };

  const updateLocalStorageUsername = (rememberMeCheckBoxVal: boolean, email: string) => {
    rememberMeCheckBoxVal ?  setLocalStorageRememberMeValues(rememberMeCheckBoxVal, email): removeLocalStorageRememberMeValues();
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validateOnMount: true,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      updateLocalStorageUsername(values.rememberMe, values.email);
      login(values.email.toLowerCase(), values.password)
        .then(({ data: { accessToken, user } }) => {
          if( user.roles.includes('SecchiAdmin') ){
            setLoading(false);
            setSubmitting(false);
            return setStatus('Secchi admins not allowed in client portal');
          }
          if(!user.company){
            setLoading(false);
            setSubmitting(false);
            return setStatus('User not attached to company. Please contact secchi support.');
          }
          // console.log(`LOGIN: reload teams - isUninitialized: ${isUninitialized} - data ${data?.length}`, data);
          dispatch(auth.actions.login(accessToken))
          // reloadTeams();
        })
        .catch((err) => {
          setLoading(false)
          setSubmitting(false)

          toast.error(err?.response?.data?.msg || 'Something went wrong!', {
            position: 'top-right',
            theme: 'colored',
            autoClose: 3000,
          });
        })
    },
  })

  return (
    <div className='bg-white mx-auto p-0 mw-500px p-20'>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='form_title text-center'>Sign in to your account</div>
        <div className="form_subtitle fs-5 text-center">Welcome back!</div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        <div className='fv-row form_control_wrapper position-relative'>
          <i className="bi bi-envelope fs-2 position-absolute"></i>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form_control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.email && formik.errors.email,
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>

        <div className='fv-row form_control_wrapper position-relative'>
          <i className="bi bi-lock fs-2 position-absolute"></i>
          <input
            placeholder='Password'
            type={isShowPwd ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form_control form-control-lg form-control-solid input_password',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <button
            type="button"
            className='position-absolute btn p-0 btn_show_password'
            onClick={() => setIsShowPwd(!isShowPwd)}
          >
            {
              isShowPwd ?
                <i className="bi bi-eye-slash-fill fs-2"></i> :
                <i className="bi bi-eye-fill fs-2"></i>
            }
          </button>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-stack mb-2 width_100'>
            <div className='form-check form-check-custom'>
              <input
                className='form-check-input remember_me_check'
                type='checkbox'
                id="remember-me"
                checked={formik.values.rememberMe}
                {...formik.getFieldProps('rememberMe')}
              />
              <label
                className='form-check-label'
                htmlFor='remember-me'
              >
                Remember me
              </label>
            </div>
            <Link
              to='/auth/forgot-password'
              className='forgot_password_link'
              style={{ marginLeft: '5px' }}
            >
              Forgot Password?
            </Link>
          </div>
        </div>

        <div className='text-center margin-top-20'>
          <button
            type='submit'
            id='login-btn'
            className='btn btn-lg btn-primary w-100 mt-10'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Sign In</span>}
            {loading && (
              <span className='indicator-progress poppins_medium' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
