import { noop } from 'lodash';
import { ReactNode } from 'react';
import { Button } from 'react-bootstrap'
import { SkeModal } from '../modals/generic/SkeModal'
import { SkeButtonLoading } from "./ButtonLoading"

interface SkeModalConfirmProps {
	show: boolean;
	title: string;
	children: ReactNode;
	cancelText?: string;
	confirmText?: string;
	onCancel: () => void;
	onConfirm: () => void;
	isLoading?: boolean;
	className?: string;
}

export function SkeModalConfirm({
	show,
	title,
	children,
	cancelText = 'Cancel',
	confirmText = 'Confirm',
	onCancel = noop,
	onConfirm = noop,
	isLoading = false,
	className = '',
}: SkeModalConfirmProps) {
	const footerContents = (
		<div className="d-flex justify-content-end gap-5 p-8">
			<Button
				variant='secondary'
				onClick={onCancel}
				disabled={isLoading}
			>
				{cancelText}
			</Button>
      <SkeButtonLoading
				variant='danger'
				onClick={onConfirm}
				isLoading={isLoading}
			>
        {confirmText}
      </SkeButtonLoading>
		</div>
	)

	return (
		<SkeModal
			show={show}
			title={title}
			onSubmit={onConfirm}
			onClose={onCancel}
			footerContents={footerContents}
		>
			{children}
		</SkeModal>
	)
}
