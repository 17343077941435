import { ReactNode, useState, useRef, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type TriggerType = "hover" | "click" | "focus";

interface SkePopoverProps {
  placement:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start";
  triggerElement: ReactNode;
  trigger: TriggerType[];
  className?: string;
  children: ReactNode;
}

export function SkePopover({
  placement = "top",
  triggerElement,
  trigger,
  className = "",
  children,
}: SkePopoverProps) {
  const [show, setShow] = useState(false);

  const triggerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const isTriggerEnabled = (type: TriggerType) => trigger.includes(type);

  const handleMouseEnter = () => {
    if (isTriggerEnabled("hover")) {
      setShow(true);
    }
  };

  const handleMouseLeave = () => {
    if (isTriggerEnabled("hover")) {
      setShow(false);
    }
  };

  const handleClick = () => {
    if (isTriggerEnabled("click")) {
      setShow((prevShow) => !prevShow);
    }
  };

  const handleFocus = () => {
    if (isTriggerEnabled("focus")) {
      setShow(true);
    }
  };

  const handleBlur = () => {
    if (isTriggerEnabled("focus")) {
      setShow(false);
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      triggerRef.current?.contains(event.target as Node) ||
      overlayRef.current?.contains(event.target as Node)
    ) {
      return;
    }
    setShow(false);
  };

  useEffect(() => {
    if (isTriggerEnabled("click") && show) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show, trigger]);

  return (
    <OverlayTrigger
      show={show}
      placement={placement}
      overlay={
        <Tooltip
          ref={overlayRef}
          id="interactive-popover-tooltip"
          className={className}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {children}
        </Tooltip>
      }
    >
      <div
        ref={triggerRef}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className='d-inline-block cursor-pointer'
      >
        {triggerElement}
      </div>
    </OverlayTrigger>
  );
}
