/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import { updateUser } from '../redux/AuthCRUD'
import { PasswordMeterComponent } from "../../../../_library/assets/ts/components";

import './LoginWithInvite.scss'

const initialValues = {
  firstname: '',
  lastname: '',
  password: '',
  changepassword: '',
  // acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 letters')
    .max(50, 'Maximum 50 letters')
    .required('First name is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 letters')
    .max(50, 'Maximum 50 letters')
    .required('Last name is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 letters')
    .max(50, 'Maximum 50 letters')
    .required('Password is required')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Must include capital letter, special' +
      ' character and number'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  // acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function LoginWithInvite() {
  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get("token")
  const [isShowPwd, setIsShowPwd] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    validateOnMount: true,
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        if (token) {
          updateUser(values.firstname, values.lastname, values.password, values.changepassword, token)
            .then(() => {
              setLoading(false)
              dispatch(auth.actions.login(token))
            })
            .catch((err) => {
              setLoading(false)
              setSubmitting(false)
              console.log(err)
              setStatus(err.response.data.message)
            })
        } else {
          setLoading(false)
          setSubmitting(false)
          setStatus("Your access token is incorrect")
        }
      }, 1000)
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <div className='m-auto d-flex flex-center flex-column flex-column-fluid p-10 px-15 mw-500px'>
      <div className='bg-white mx-auto'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='form_title text-center mb-10'>Set up Account</div>

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <div className='row fv-row form_control_wrapper'>
            <div className='col-xl-6'>
              <div className='fv-row mb-5'>
                <input
                  placeholder='First name'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('firstname')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid px-5',
                    {
                      'is-invalid': formik.touched.firstname && formik.errors.firstname,
                    },
                    {
                      'is-valid': formik.touched.firstname && !formik.errors.firstname,
                    }
                  )}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>{formik.errors.firstname}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='fv-row mb-5'>
                <input
                  placeholder='Last name'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('lastname')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid px-5',
                    {
                      'is-invalid': formik.touched.lastname && formik.errors.lastname,
                    },
                    {
                      'is-valid': formik.touched.lastname && !formik.errors.lastname,
                    }
                  )}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>{formik.errors.lastname}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mb-10 fv-row form_control_wrapper' data-kt-password-meter='true'>
            <div className='mb-1'>
              <div className='position-relative form_control_wrapper'>
                <i className="bi bi-lock fs-2 position-absolute"></i>
                <input
                  type={isShowPwd ? 'text' : 'password'}
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid no-error-icon',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
                <button
                  type="button"
                  className='position-absolute btn p-0 btn_show_password'
                  onClick={() => setIsShowPwd(!isShowPwd)}
                >
                  {
                    isShowPwd ?
                      <i className="bi bi-eye-slash-fill fs-3"></i> :
                      <i className="bi bi-eye-fill fs-3"></i>
                  }
                </button>
              </div>
              <div
                className="d-flex align-items-center mb-3"
                data-kt-password-meter-control="highlight"
              >
                <div
                  className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                ></div>
                <div
                  className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                ></div>
                <div
                  className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                ></div>
                <div
                  className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
                ></div>
              </div>
            </div>
            <div className="text-muted">
              Use 8 or more characters with a mix of upper- and lowercase letters, numbers & symbols.
            </div>
          </div>
          <div className='fv-row form_control_wrapper'>
            <input
              type='password'
              placeholder='Password confirmation'
              autoComplete='off'
              {...formik.getFieldProps('changepassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid px-5',
                {
                  'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                },
                {
                  'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                }
              )}
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className='text-danger'>{formik.errors.changepassword}</span>
                </div>
              </div>
            )}
          </div>
          <div className='text-center mt-10'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg w-100 mb-5 btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
