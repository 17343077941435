import { toUpper, trim } from 'lodash';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Employee } from '../../../app/modules/employee/models/Employee.model';
import { EmployeeType } from '../../../app/types/EmployeeType';
import { SkeModalConfirm } from "../../../common/components/ModalConfirm";
import { SkeModal } from "../../../common/modals/generic/SkeModal";

interface Props {
	employee: EmployeeType | Employee;
	show?: boolean;
	onClose?: () => void;
	onConfirm?: () => void;
}

export function EmployeeDeleteModal({
																			employee,
																			show,
																			onClose,
																			onConfirm,
																		}: Props) {
	const [confirmButtonShow, setConfirmButtonShow] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [internalShowEditEmployeeModal, setInternalShowEditEmployeeModal] = useState(true);
	const [confirmInputValue, setConfirmInputValue] = useState('');

	const resetConfirmValidation = () => {
		setConfirmButtonShow(false);
		setConfirmInputValue('');
	}

	const handleClose = () => {
		if (onClose) {
			resetConfirmValidation();
			onClose();
		}
	};

	const handleShowConfirmDeleteModal = () => {
		setInternalShowEditEmployeeModal(false);
		setShowConfirmModal(true);
	}

	const handleOnCancel = () => {
		setShowConfirmModal(false);
		setInternalShowEditEmployeeModal(true);
	}

	const handleOnDeleteConfirm = () => {
		setShowConfirmModal(false);
		resetConfirmValidation();

		if(onConfirm) onConfirm();
	}

	const handleValidateConfirm = (e: any) => {
		const val = e.target.value;
		setConfirmInputValue(val);
		setConfirmButtonShow(trim(toUpper(e.target.value)) === 'CONFIRM');
	};

	const footerContents = (
		<div
			className="d-flex justify-content-end gap-5 p-8"
		>
			<Button
				className="px-10"
				variant="secondary"
				onClick={handleClose}>
				Close
			</Button>
			<Button
				className="px-10"
				variant="danger"
				disabled={!confirmButtonShow}
				onClick={handleShowConfirmDeleteModal}
			>
				Confirm
			</Button>
		</div>);

	return (
		<>
			<SkeModal
				show={show && internalShowEditEmployeeModal}
				title={`Delete ${employee.firstName} ${employee.lastName}`}
				onClose={handleClose}
				footerContents={footerContents}
			>
				<div className="alert alert-danger border border-danger rounded p-4">
					<div className="d-flex align-items-start gap-2">
						<i className="bi bi-exclamation-triangle-fill text-danger me-3" style={{ fontSize: '2.5rem' }}></i>
						<div>
							<h5 className="fw-bold fs-3 mb-5">Warning: Permanent Deletion</h5>
							<p>
								By deleting <strong>{employee.firstName} {employee.lastName}</strong>, the employee and all connections associated
								with them will be <strong>permanently</strong> deleted.
							</p>
							<p>
								Please review the <strong>Connection History</strong> on their page to confirm those records can be deleted.
								Deletion of connection data will alter <strong>engagement metrics</strong> for the supervisor and company.
							</p>
							<p>
								<strong>Note:</strong> If you are terminating an employee, you should click <strong>"edit"</strong> on their
								employee profile and change them to <strong>Terminated</strong> status instead of deleting all records.
							</p>
							<p>If you want to proceed, please type in <b>"confirm"</b>.</p>
							<div className="mb-6 col-8">
								<input
									type="text"
									onChange={handleValidateConfirm}
									value={confirmInputValue}
									className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
									placeholder=""
								/>
							</div>
						</div>
					</div>
				</div>
			</SkeModal>

			{ showConfirmModal && (
				<SkeModalConfirm
					show={showConfirmModal}
					title="Confirm Delete Employee"
					onCancel={handleOnCancel}
					onConfirm={handleOnDeleteConfirm}
				>
					<div className="alert alert-danger d-flex align-items-center gap-5 m-0" role="alert">
						<i className="bi bi-trash text-danger" style={{fontSize: '2rem'}}></i>
						<div>
							<h4 className="alert-heading">Warning This Cannot Be Undone!</h4>
							<p>YOU ARE ABOUT TO PERMANENTLY DESTROY ALL RECORDS FOR THIS EMPLOYEE, AND YOU WILL NOT BE ABLE TO RECOVER THEM! ARE YOU SURE THAT YOU WANT TO CONTINUE?</p>
						</div>
					</div>
				</SkeModalConfirm>)
			}
		</>
	);
}

