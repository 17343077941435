import { ReactNode, useEffect, useState } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { SkeBeta } from '../../components/beta';
import { CloseButtonIcon } from '../../../app/components/icons/CloseButtonIcon';

interface Props extends ModalProps {
	title: string;
	subtitle?: ReactNode | string;
	titleContents?: ReactNode;
	onClose?: (action: boolean) => void;
	onSubmit?: () => void;
	show?: boolean;
	children?: ReactNode;
	showHeader?: boolean;
	headerContents?: ReactNode;
	customHeaderContents?: ReactNode;
	showFooter?: boolean;
	footerContents?: ReactNode;
	beta?: boolean;
	size?: 'sm' | 'lg' | 'xl';
}

export function SkeModal({
	title,
	subtitle,
	titleContents,
	beta,
	size,
	show,
	onClose,
	onSubmit,
	children,
	showHeader = true,
	headerContents,
	customHeaderContents,
	showFooter = true,
	footerContents,
	modalBodyCustomClass,
	...props
}: Props) {
	const [showModal, setShowModal] = useState(show);

	const handleClose = () => {
		if (onClose) {
			onClose(false);
		}
	};

	const handleOnSubmit = () => {
		if (onSubmit) {
			onSubmit();
		}
	};

	useEffect(() => {
		setShowModal(show)
	}, [show]);

	return (
		<>
			<Modal
				size={size}
				show={showModal}
				onHide={handleClose}
				backdrop="static"
				scrollable={true}
				{...props}
			>
				{showHeader && (
					<>
						{customHeaderContents && (
							<Modal.Header className='d-block'>
								{<button
									style={{top: '20px', right: '20px'}}
									className="position-absolute position-absolute border-0 bg-transparent p-0"
									onClick={() => handleClose()}
								>
									<CloseButtonIcon size="md" />
								</button>}
								{customHeaderContents}
							</Modal.Header>
						)}
						{!customHeaderContents && headerContents && (
							<>
								<Modal.Header closeButton>
									<div>{headerContents}</div>
									<Modal.Title>
										{title}{' '}
										{beta && (
											<span>
												<SkeBeta></SkeBeta>
											</span>
										)}
										{subtitle && (
											<>
												<div>
													<span className='fs-3 text-muted'>{subtitle}</span>
												</div>
											</>
										)}
									</Modal.Title>
								</Modal.Header>
							</>
						)}
						{!customHeaderContents && !headerContents && (
							<>
								<Modal.Header closeButton>
									<Modal.Title>
										{title}{' '}
										{beta && (
											<span>
												<SkeBeta></SkeBeta>
											</span>
										)}
										{titleContents}
									</Modal.Title>
								</Modal.Header>
							</>
						)}
					</>
				)}
				<Modal.Body className={modalBodyCustomClass}>
					{children}
				</Modal.Body>
				{showFooter && (
					<>
						{footerContents && <>{footerContents}</>}
						{!footerContents && (
							<>
								<Modal.Footer>
									<Button variant='secondary' onClick={handleClose}>
										Cancel
									</Button>
									<Button variant='primary' onClick={handleOnSubmit}>
										Save Changes
									</Button>
								</Modal.Footer>
							</>
						)}
					</>
				)}
			</Modal>
		</>
	)
}
