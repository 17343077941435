import { pick, values } from 'lodash';
import { useState, useEffect } from 'react';
import { PerformanceReportConfigType, PerformanceReportOption } from '../../../../types/PerformanceReportConfigType';
import DatePicker from 'react-datepicker';
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import './EmployeePerformanceCardConfig.scss';
import { SkeModal } from "../../../../../common/modals/generic/SkeModal";

interface Props {
	config: PerformanceReportConfigType;
	setConfig: (config: PerformanceReportConfigType) => void;
	onGenerateReport: (config: PerformanceReportConfigType) => void;
	show: boolean;
	onClose: () => void;
}

export const EmployeePerformanceCardConfig = ({
																								config,
																								setConfig,
																								onGenerateReport,
																								show,
																								onClose,
																							}: Props) => {
	const handleOptionToggle = (type: PerformanceReportOption) => {
		let additionalUpdate: {[key: string]: boolean} = {};
		if (type === 'attendance' && config.attendance) {
			additionalUpdate.excused = false;
		}
		if (type === 'showCalendar') {
			additionalUpdate.showCalendar = !config.showCalendar;
		}
		setConfig({
			...config,
			[type]: !config[type],
			...additionalUpdate,
		});
	};

	const isGenerateEnabled = function() {
		return values(pick(config, 'attendance', 'excused', 'coaching', 'discipline', 'recognition')).includes(true);
	};

	const [startDate, setStartDate] = useState<Date>(moment().subtract(1, 'years').toDate());
	const [endDate, setEndDate] = useState<Date>(moment().add(1, 'days').toDate());

	// whenever startDate or endDate changes, useEffect updates config
	useEffect(() => {
		if (startDate && endDate) {
		  setConfig({
				...config,
				startDate,
				endDate,
		  });
		}
	}, [startDate, endDate]);

	return (
		<SkeModal
			title="Instant Performance Report"
			show={show}
			footerContents={
				<div className="mt-3 d-flex justify-content-end p-8">
					<button
						type="button"
						className="btn btn-sm btn-primary"
						onClick={() => onGenerateReport(config)}
						disabled={!isGenerateEnabled()}
					>
						Generate Report
					</button>
				</div>
			}
			onClose={onClose}
		>
				<>
					<div className="mx-0">
						<h5 className="subtitle mb-4">
							Select connections to include
						</h5>
						<div className="d-flex flex-column gap-3">
							<div className="form-check form-check-custom form-check-solid gap-3">
								<input
									id="recognition"
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.recognition}
									onChange={() => handleOptionToggle('recognition')}
								/>
								<label
									htmlFor="recognition"
									className="align-items-start"
								>
									Recognition
								</label>
							</div>
							<div className="form-check form-check-custom form-check-solid gap-3">
								<input
									id="coaching"
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.coaching}
									onChange={() => handleOptionToggle('coaching')}
								/>
								<label
									htmlFor="coaching"
									className="align-items-start"
								>
									Coaching
								</label>
							</div>
							<div className="form-check form-check-custom form-check-solid gap-3">
								<input
									id="attendance"
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.attendance}
									onChange={() => handleOptionToggle('attendance')}
								/>
								<label
									htmlFor="attendance"
									className="align-items-start"
								>
									Attendance
								</label>
							</div>
							<div className="form-check form-check-custom form-check-solid gap-3">
								<input
									id="excused"
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.excused}
									onChange={() => handleOptionToggle('excused')}
								/>
								<label
									htmlFor="excused"
									className="align-items-start"
								>
									Excused
								</label>
							</div>
							<div className="form-check form-check-custom form-check-solid gap-3">
								<input
									id="discipline"
									className="align-items-end form-check-input"
									type="checkbox"
									checked={config.discipline}
									onChange={() => handleOptionToggle('discipline')}
								/>
								<label
									htmlFor="discipline"
									className="align-items-start"
								>
									Corrective
									Action</label>
							</div>
						</div>
						<div className="mt-10">
							<h5 className="mb-4">Report Date Range</h5>
								<div className="d-flex align-items-center gap-4">
									<div className="form-check form-check-custom gap-0 form-switch form-check-solid m-0 date-picker-margin flex-column justify-content-start align-items-start"
									style={{ marginRight: '10px' }}
									>
									<label className="align-items-start">Start Date</label>
									<DatePicker
										className="form-control"
										selected={startDate}
										onChange={(date) => {
											if (date) {
												setStartDate(date);
											}
										}}
									/>
									</div>

									<div className="form-check form-check-custom gap-0 form-switch form-check-solid m-0 date-picker-margin flex-column justify-content-start align-items-start">
									<label className="align-items-start">End Date</label>
									<DatePicker
										className="form-control"
										selected={endDate}
										onChange={(date) => {
											if (date) {
												setEndDate(date);
											}
										}}
									/>
									</div>
								</div>
							</div>

						<div className="mt-10">
							<h5 className="mb-4">Report View</h5>
							<div>
								<div className="form-check form-check-custom form-switch form-check-solid d-flex gap-3 justify-content-end align-items-center"
									style={{ flexDirection: 'row-reverse' }}
								>
									<label
										htmlFor="chronologicalOrder"
										className="align-items-start"
									>Chronological Order</label>
									<input
										id="chronologicalOrder"
										className="align-items-end form-check-input"
										type="checkbox"
										checked={config.combineConnections}
										onChange={() => handleOptionToggle('combineConnections')}
									/>
								</div>
								<div
									className="form-check form-check-custom form-switch form-check-solid mt-2 d-flex gap-3 justify-content-end align-items-center flex-row-reverse">
									<label
										htmlFor="showCalendar"
										className="align-items-start flex-row-reverse"
									>
										Show Calendar
									</label>
									<input
										id="showCalendar"
										className="align-items-end form-check-input"
										type="checkbox"
										checked={config.showCalendar}
										onChange={() => handleOptionToggle('showCalendar')}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
		</SkeModal>
	);
};
