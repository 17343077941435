import clsx from "clsx";

interface SkeBetaProps {
	className?: string
}

export function SkeBeta({className}: SkeBetaProps) {
	return (
		<span className={clsx("mx-5 badge rounded-pill bg-info", className)}>Beta</span>
	);
}
