import { get, isDate } from 'lodash';
import moment from 'moment';
import { ConnectionTypeEnumWithCa } from '../../../../features/connection/interfaces/connection.model';
import { ConnectionDetails, VwConnection } from '../../../../features/connection/interfaces/vw_connection.model';
import { Recognition } from '../../../../features/recognition/interfaces/recognition.model';
import { DateFormatUsingDateFns, US_FRIENDLY_DATE } from '../../../modules/date/DateFormat.const';
import { parseDateObjectToNewFormat } from '../../../modules/date/utils/DateHelpers';
import { Discipline } from '../../../modules/discipline/models/Discipline.models';
import { AttendanceType } from '../../../types/AttendanceType';
import { CoachingType } from '../../../types/CoachingType';
import { DisciplineType } from '../../../types/DisciplineType';
import { ConnectionHistoryAttendanceRow } from './ConnectionHistoryAttendanceRow';
import { ConnectionHistoryCoachingRow } from './ConnectionHistoryCoachingRow';
import { ConnectionHistoryCorrectiveActionRow } from './ConnectionHistoryCorrectiveActionRow';
import { ConnectionHistoryRecognitionRow } from './ConnectionHistoryRecognitionRow';
import { ConnectionHistoryReviewRow } from './ConnectionHistoryReviewRow';

interface Props {
	combinedConnections: (AttendanceType | CoachingType | DisciplineType | Recognition | VwConnection)[];
	onAttendanceClick?: (attendance: AttendanceType, action: 'view' | 'edit' | 'delete') => void;
	onCoachingClick?: (coaching: CoachingType, action: 'view' | 'edit' | 'delete') => void;
	onCorrectiveActionClick?: (correctiveAction: Discipline, action: 'view' | 'edit' | 'delete') => void;
	startDate?: Date;
	endDate?: Date;
	showResetButton?: boolean;
	onResetDateSelection?: () => void;
}

export function ConnectionHistory({
																		combinedConnections,
																		onAttendanceClick,
																		onCoachingClick,
																		onCorrectiveActionClick,
																		startDate,
																		endDate,
																		showResetButton = false,
																		onResetDateSelection,
																	}: Props) {
	let previousDate: string | undefined = '';
	return (
		<div className="mb-6 mb-xl-9">
			<div className="d-flex mt-6 justify-content-start flex-row">
				<div className="">
					<h2 className="mb-1">Connection History
						{!!startDate && (
							<>
								{(!endDate || moment(startDate).isSame(endDate, 'day')) && (
									<span className="text-muted fw-light"> showing {moment(startDate).format(US_FRIENDLY_DATE)}</span>
								)}
								{(!!endDate && !moment(startDate).isSame(endDate, 'day')) && (
									<span className="text-muted fw-light"> showing {moment(startDate).format(US_FRIENDLY_DATE)} to {moment(endDate).format(US_FRIENDLY_DATE)}</span>
								)}
							</>
						)}
					</h2>
				</div>
				{showResetButton && (
					<button
						className="btn btn-outline-danger ms-auto"
						onClick={() => {
							if (onResetDateSelection) {
								onResetDateSelection();
							}
						}}
					>Reset Date</button>
				)}
			</div>
			<div className="pt-4">
				<table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
					<thead>
						<tr className="fw-bolder text-muted">
							<th>Date</th>
							<th>Type</th>
							<th>Category</th>
							<th>Notes</th>
							<th>Creator</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{combinedConnections.map((item, i) => {
							const skipDate = (item.date === previousDate);
							const connType = get(item, 'connectionType', get(item, 'connection_type', undefined));
							if (!connType) {
								console.error(`Unable to process connection as no type was found: "${connType}`, item);
								return;
							}
							previousDate = isDate(item.date) ? parseDateObjectToNewFormat(item.date, DateFormatUsingDateFns.PerfectDate) : item.date;
							switch (connType) {
								case ConnectionTypeEnumWithCa.Attendance:
									return (
										<ConnectionHistoryAttendanceRow
											attendance={(item as AttendanceType)}
											rxKey={i}
											key={i}
											onClick={(att) => {
												if (onAttendanceClick) {
													onAttendanceClick(att, 'view');
												}
											}}
											onActionClick={(att, action) => {
												if (onAttendanceClick) {
													onAttendanceClick(att, action);
												}
											}}
											showDate={!skipDate}
										/>
									);
								case ConnectionTypeEnumWithCa.Coaching:
									return <ConnectionHistoryCoachingRow
										coaching={(item as CoachingType)}
										rxKey={i}
										key={i}
										onClick={(coa) => {
											if (onCoachingClick) {
												onCoachingClick(coa, 'view');
											}
										}}
										onActionClick={(coa, action) => {
											if (onCoachingClick) {
												onCoachingClick(coa, action);
											}
										}}
										showDate={!skipDate} />;
								case ConnectionTypeEnumWithCa.Recognition:
									return <ConnectionHistoryRecognitionRow
										recognition={(item as unknown as Recognition)}
										rxKey={i}
										key={i}
										showDate={!skipDate} />;
								case ConnectionTypeEnumWithCa.Discipline:
									return (
										<ConnectionHistoryCorrectiveActionRow
											correctiveAction={(item as unknown as Discipline)}
											record={(item as unknown as Discipline)}
											rxKey={i}
											key={i}
											onActionClick={(corr, action) => {
												if (onCorrectiveActionClick) {
													onCorrectiveActionClick(corr, action);
												}
											}}
											showDate={!skipDate}
										/>
									);
								case ConnectionTypeEnumWithCa.Review:
									return (
										<>
											<ConnectionHistoryReviewRow
												record={(item as unknown as VwConnection<ConnectionDetails>)}
												rxKey={item.id}
												showDate={!skipDate}
											/>
										</>
									);
								default:
									return null;
							}
						})
						}
					</tbody>
				</table>
			</div>
		</div>
	);
}
