import { noop } from 'lodash';
import { ReactNode } from 'react';
import { Button, Spinner } from 'react-bootstrap'
import { ButtonVariant } from 'react-bootstrap/types';

interface SkeButtonLoadingProps {
  variant?: ButtonVariant;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  loadingMsg?: string;
}

export function SkeButtonLoading({
  variant = 'primary',
  onClick = noop,
  isLoading = false,
  disabled = false,
  children,
  loadingMsg = 'Loading...',
}: SkeButtonLoadingProps) {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          {loadingMsg && <span className="visually-hidden">{loadingMsg}</span>}
        </>
      ) : (
        children
      )}
    </Button>
  )
}
