import { PageTitle } from '../../../_library/layout/core';
import { ConnectionsGrid } from '../../../features/connection/pages/ConnectionsGrid';

export function ConnectionsList() {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Connections</PageTitle>
			<ConnectionsGrid />
		</>
	);
};
